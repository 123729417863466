import React from 'react';
import styles from './Background.module.css';

function Background() {
    return (
        <div>
            <div className={styles.background}>
            </div>
        </div>)
}

export default Background;