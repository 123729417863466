export const HPWhoIAm = "Hi! I'm Adam Rideout. I'm a software engineer based in San Francisco. I specialize in frontend web development but I also have experience with backend, data science, machine learning, Web3 and more! Click below to learn more!"

export const HPContact = "I'm always interested in connecting with people. Whether it's to discuss projects, opportunities or just to get to know each other better, don't hesitate to reach out!"

export const HPWhatIDo = "I like to build things. I've built cutting edge websites, a machine learning model to detect heart disease, and a blockchain integrated blog service just to name a few. Learn more about my portfolio of projects below."

export const AbMission = "I build frontends that are intuitive, sleek, and fast. Too often, software tools aren’t designed for the average user. Software should be accessible to everyone, not just those of us who were lucky enough to grow up surrounded by technology. My goal is to master frontend development so that I can create products that users are excited to interact with."

export const AbMissionFast = "I stay up to date on the latest technologies, industry trends and code tricks to ensure that the frontends I build are always cutting edge and responsive. To see more about the technologies I've used and the projects they've been a part of, check out my portfolio page"

export const Hobbies = "In my free time I enjoy the outdoors through rock climbing, hiking, snowboarding and lots of other activities."

export const Blog1Text = "React components are the building blocks of modern websites. This post details how to set them up, the difference between class and functional components, props, state and more."

export const Blog2Text = "Redux is a state managment tool to help power up your react applications. This post explores why you would choose to use redux and some of the basic structures"