import React from 'react';
import ComingSoon from "../Utils/ComingSoon"

function Unlock() {

    return (<div>
        <ComingSoon />
    </div>)
}

export default Unlock;