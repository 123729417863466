import React from 'react';
import ComingSoon from "../Utils/ComingSoon"

function MMS() {

    return (<div>
        <ComingSoon />
    </div>)
}

export default MMS;