export const MenuItems = [
    {
        title: "Home",
        url: "/",
        cName: 'nav-links'
    },
    {
        title: "About",
        url: "/about",
        cName: 'nav-links'
    },
    {
        title: "Portfolio",
        url: "/Portfolio",
        cName: 'nav-links'
    },
    {
        title: "Contact Me",
        url: "/contact",
        cName: 'nav-links'
    },
]