import React from 'react';
import ComingSoon from "../Utils/ComingSoon"

function Eko() {

    return (<div>
        <ComingSoon />
    </div>)
}

export default Eko;