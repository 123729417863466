import React from 'react';
import ComingSoon from "../Utils/ComingSoon"

function Palantir() {

    return (<div>
        <ComingSoon />
    </div>)
}

export default Palantir;