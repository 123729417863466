import React from 'react';
import ComingSoon from "../Utils/ComingSoon"

function Website() {

    return (<div>
        <ComingSoon />
    </div>)
}

export default Website;