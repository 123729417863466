import React from 'react';
import JackHammer from "../../images/jackhammer.png"
import ComingSoon from '../Utils/ComingSoon';
import styles from "./Hobbies.module.css"

function Hobbies() {
    return (<div>
        <ComingSoon />
    </div >)
}

export default Hobbies;